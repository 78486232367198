.nav{
    display:flex;
    background-color: #f0f0f0;
    margin-bottom: 20px;
    padding: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add the box-shadow here */
    justify-content: space-between;
}
.nav h1{
    color: black;
}