.rowComponent {
    background: linear-gradient(-30deg, #f0f0f0 70%, white 70%);
    border-radius: 2px;
    margin: 10px 20px;
    border-top: 2px solid grey;
    padding: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
    cursor: pointer;
    user-select: none;

  }
  
  .rowComponent:hover {
    background: linear-gradient(-30deg, rgba(57, 146, 248, 0.2)70%, rgba(181, 231, 248, 0.2) 70%);
    border-top: 2px solid rgb(114, 142, 175);

  }
  