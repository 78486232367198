/* Add this to your CSS or use inline styles in the Navbar component */
.navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #333;
    padding: 10px;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .navbar h1{
    color: white;
  }