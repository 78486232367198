.rfl-container {
    /* display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center; */
    padding-top: 50px;
  }
  
  .logo-container {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }
  
  .logo {
    width: 80px; /* Adjust the width of the logo as needed */
    height: auto;
    margin-right: 8px;
  }
  
  .title {
    font-size: 56px; /* Adjust the font size as needed */
    font-weight: bolder;
}
  
  .summary {
    max-width: 700px;
    margin: 0 auto;
    margin-top: -50px;
    padding-top: 10px;
    border-top: 2px solid darkslategray
  }
  

  
  .team-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 50vh;
    padding: 20px;
    overflow-y: auto; /* Add this line to enable vertical scrolling */
  }
  
  
  .team-card {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .team-logo {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 20px;
  }
  
  .team-info-container {
    display: flex;
    flex-direction: column;
  }
  
  .team-name {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 8px;
  }
  
  .team-info {
    margin-bottom: 4px;
  }
  
  .column-container {
    display: grid;
    grid-template-columns: 20% 50% 20%; /* Sets the widths of the columns */
    gap: 5%; /* Adds some space between columns */
    margin: 0 auto; /* Centers the container horizontally */
  }
  
  .column {
    border: 1px solid red;
  }
  