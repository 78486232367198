.options-container {
  display: flex;
  flex-direction: column; /* Added this line */
  z-index: 9999;
  width: 104%;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 85vh;
  border-bottom: 2px solid purple;
  margin-left: -20px;
  padding-left: 20px;
  padding-bottom: 20px;
}

.asset-item {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px 5px;
  border-bottom: 1px solid #E2E8F0;
}

.asset-details {
  display: flex;
  align-items: center;
  width: 100%;
}
.extra-buttons {
  margin-left: auto;
}
/* .extra-buttons button{
  margin: -15px;
} */


/* Rest of the CSS styling for other classes */


.asset-item.collapsed {
  display: none;
}

.asset-item button {
  margin-right: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.asset-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

}

.asset-name {
  flex-grow: 1;
  font-size: 18px; /* Adjust the font size for the asset name */
  font-weight: bold; /* Set the font weight for the asset name */
}

.asset-type {
  margin-top: -5px;
  font-size: 14px; /* Adjust the font size for the asset type */
  color: #A0AEC0; /* Set the color for the asset type */
}

.move-button-container {
  margin-top: -40px;
}

.move-button {
  margin-left: auto;
  display: flex;
  align-items: flex-end;
}

.move-button button {
  margin-left: 25px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 20px; /* Adjust the font size for the move buttons */
  color: #A0AEC0; /* Set the color for the move buttons */
}

.remove-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}


.more-items-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}


.more-items-button {
  border:none;
  background-color: transparent;
  transition: background-color 0.3s ease;
  width: 100%;
  padding: 5px 15px;
  text-align: left;

}

.more-items-button:hover:not([disabled]) {
  background-color: rgba(212, 12, 196, 0.1);
  cursor: pointer;
}

.more-items-button[disabled] {
  cursor: default;
}

.more-items-button[disabled]:hover {
  background-color: lightgrey;
}

.modal-button {
  display: inline-block;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  background-color: purple;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 5px;
  width: 100%;
}

.modal-button.active {
  background-color: purple;
}

.modal-button:hover {
  background-color: rgb(173, 9, 173);
}


/* MODAL */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(2px); /* Apply a blur effect to the content behind the modal */
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
}

.modal-content {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 80vw;
  height: 80vh;
  padding: 16px;
  overflow-y: scroll;
  border-radius: 10px;
  background-color: white;
}

.modal-content-ontop {
  position: sticky; /* Set position to sticky */
  top: -3%; /* Stick to the top of the container */
  background-color: white;
  border-bottom: 2px solid purple;
  height: 10%;
  z-index: 2; /* Ensure it's on top of other elements */
  width: 100%;
}


.modal-item {
  display: inline-block;
  cursor: pointer;
  max-width: 250px;
  margin-right: 16px; /* Add margin between the images */
}

.modal-item:last-child {
  margin-right: 0; /* Remove the margin for the last image */
}
.modal-item img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.modal-item.selected {
  border: 2px solid #007BFF;
  border-radius: 4px;
}

.modal-content::-webkit-scrollbar {
  width: 6px; /* Adjust the width of the scrollbar as needed */
}

.modal-content::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3); /* Customize the color of the scrollbar thumb */
  border-radius: 3px;
}

.modal-content::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1); /* Customize the color of the scrollbar track */
  border-radius: 3px;
}

.modal-close-button {
  position: absolute;
  top: 10px; /* Adjust the distance from the bottom as needed */
  left: 10px; /* Adjust the distance from the right as needed */
  z-index: 1;
  display: inline-block;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  background-color: purple;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 5px;
}

.modal-search-bar {
  position: absolute;
  top: 10px;
  left: 100px;
  width: 90%;
  z-index: 1;
  display: inline-block;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  color: purple; /* Set the text color to purple */
  font-weight: normal; /* Remove the bold font-weight */
  background-color: transparent; /* Set the background color as transparent */
  transition: background-color 0.3s ease;
  margin: 5px;
}

.modal-search-bar:focus {
  outline: none;
  background-color: rgba(128, 0, 128, 0.1); /* Set the faint purple background color */
}



/*FILTER*/
.filter-buttons {
  margin-bottom: 16px;
}

.filter-button {
  padding: 8px 16px;
  background-color: #f2f2f2;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 8px;
}

.filter-button.active {
  background-color: #007BFF;
  color: #ffffff;
}
.color-preview-box {
  width: 24px;
  height: 24px;
  border: 1px solid #ccc;
  cursor: pointer;
}

.color-picker-modal {
  position: absolute;
  top: 50px;
  left: 400px;
  z-index: 9999;
  padding: 20px;
  box-sizing: border-box;
  background-color: rgb(253, 253, 253);
  border: 1px solid rgb(230, 230, 230);
  border-radius: 10px;
}

.pickers-wrapper {
  display: flex;
}

.chrome-picker-wrapper {
  margin-right: 10px;
}

.circle-picker-wrapper {
  flex-grow: 1;
  margin-bottom: 30px;
}

.color-picker-close-button{
  display: inline-block;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  background-color: purple;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 5px;
  position: absolute;
  bottom: -30px;
  right: -10px;
}

.color-picker-close-button {
  background-color: purple;
}

.color-picker-close-button {
  background-color: rgb(173, 9, 173);
}

.color-picker-container {
  /* Your existing styles */
  position: relative;
}

.sub-type{
  opacity: 0.7;
  font-size: 12px;
  text-transform: uppercase;
}

.custom-file-upload {
  position: relative;
  display: inline-block;
  margin-top: 15px;
  margin-left: -2px;
  width: 100%;
}

.hidden-input {
  display: none;
}

.custom-button {
  /* Add your custom styles for the button */
  /* Example styles */
  padding: 10px 15px;
  background-color: purple;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.custom-button:hover{
  background-color: rgb(156, 3, 156);
}


.item-text-input {
  width: 100%;
  border: none;
  border-radius: 4px;
  color: purple; /* Set the text color to purple */
  background-color: rgba(128, 0, 128, 0.2); /* Set the faint purple background color */
  font-weight: normal; /* Remove the bold font-weight */
  transition: background-color 0.3s ease;
}

.item-text-input:focus {
  outline: none;
  background-color: rgba(128, 0, 128, 0.1); /* Set the faint purple background color */
}

.items-span{
  width: 50%;
}

.custom-select {
  border: none;
  border-bottom: 1px solid #ccc;
  border-radius: 0;
  box-shadow: none;
  background-color: transparent;
  cursor: pointer;
  width: 95%;
}

.custom-select:hover {
  border-bottom: 1px solid #999;
}

.custom-option {
  cursor: pointer;
  background-color: transparent;
}

.custom-option:hover {
  background-color: #f5f5f5;
}

.font-input{
  padding: 5px; 
  width: 30%;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.3s ease;
}
.font-input:focus {
  border-color: purple;
}

.font-button {
  display: inline-block;
  border: none;
  border-radius: 4px;
  background-color: purple;
  color: #ffffff;
  font-size: 14px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 5px;
  padding: 5px 15px;
}

.font-button.active {
  background-color: purple;
}

.font-button:hover {
  background-color: rgb(173, 9, 173);
}


input[type="checkbox"] {
  /* Add your desired default styles here */
  cursor: pointer;
}

/* Custom styles for the checkbox when checked */
input[type="checkbox"]:checked + .purpleCheckboxLabel::before {
  /* Change the color to purple */
  background-color: purple;
  /* Add any other desired styles */
}


.brand-text-input {
  width: 95%;
  border: none;
  border-radius: 4px;
  color: purple; /* Set the text color to purple */
  background-color: rgba(128, 0, 128, 0.2); /* Set the faint purple background color */
  font-weight: normal; /* Remove the bold font-weight */
  transition: background-color 0.3s ease;
  font-size: large;
}

.brand-text-input:focus {
  outline: none;
  background-color: rgba(128, 0, 128, 0.1); /* Set the faint purple background color */
}