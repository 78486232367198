.home-container {
    position: relative;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: antiquewhite;
    overflow-y:hidden ;
  }
  
  .rising-image {
    width: 50%; /* Adjust the percentage to your desired size */
    height: auto;
    animation: rise-up 5s forwards;
    transition: opacity 1s;
    opacity: 0;
  }
  
  
  @keyframes rise-up {
    0% {
      transform: translateY(100vh);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  .animate {
    transform: none !important;
    opacity: 1 !important;
  }
  