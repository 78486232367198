/* @import url('https://fonts.googleapis.com/css2?family=Cherry+Bomb+One&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Rammetto+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@400;600;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@600;800&family=PT+Sans+Caption&display=swap'); */

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


/*SIDEBAR CSS*/
.sidebar {
  position: relative;
  width: 100%;
  height: 100vh; /* Set the sidebar height to fill the viewport */
  overflow: hidden; /* Hide any overflowed shapes */
  background-color: antiquewhite;
}


/*RESUME CSS*/
/* Global Styles */


.container {
  display: flex;
  height: 100vh;
}


body {
  margin: 0;
  padding: 0;
  /* background-color: #f1f0e2; */
  font-family: 'PT Sans Caption', sans-serif;
  color:darkslategray;
  /* background-image: linear-gradient(to right, rgba(0, 0, 0, 0.05) 1px, transparent 1px), 
  linear-gradient(to bottom, rgba(0, 0, 0, 0.05) 1px, transparent 1px);
  background-size: 30px 30px; */
}

.resume {
  min-width: 1000px;
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px 100px;
  overflow-y: scroll;
  scrollbar-width: thin; /* Set the width of the scrollbar */
}

.resume::-webkit-scrollbar {
  width: 20px; /* Set the width of the scrollbar */
}

.resume::-webkit-scrollbar-thumb {
  background-color: darkslategray; /* Set the color of the scrollbar thumb */
  border-radius: 14px; /* Set the border-radius to round the thumb */
  border: 4px solid white;
}

@keyframes slideDown {
  0% {
    height: 0;
  }
  100% {
    height: 105px;
  }
}

.resume-header {
  margin: auto;
  margin-top: 50px;
  width: fit-content;
  filter: drop-shadow(0px 0px 0px #cacaca);
  animation: animateDropShadow 1s ease-in-out forwards;
}

@keyframes animateDropShadow {
  0% {
    filter: drop-shadow(0px 0px 0px #cacaca);
  }
  100% {
    filter: drop-shadow(5px 2.5px 0px #dadada);
  }
}


.header-line{
  color: darkslategray;
  border: 3.5px solid darkslategray;
  width: 350px;
  border-radius: 10px;
  margin-bottom: -5px;
  margin-top: -5px;
}

.sub-header-line{
  color: darkslategray;
  border: 2.5px solid darkslategray;
  width: 100%;
  border-radius: 10px;
  margin-bottom: -5px;
  margin-left: 0;
}

.dropdown {
  overflow: hidden;
  animation: slideDown 1.5s ease forwards;
  animation-delay: 1.5s; /* Add a 3-second delay before animation starts */
  background-color: darkslategray;
  color:white;
  width: fit-content;
  margin: auto;
  padding: 0 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  height: 0;
}
.dropdown-container {
  height: 125px;
}

/* Header Styles */
header {
  text-align: center;
  margin-bottom: 30px;
}

h1 {
  /* font-family: 'Rammetto One', cursive; */
  font-size: 42px;
  margin: 0;
  color: darkslategrey;
}

h2 {
  font-family: 'Montserrat Alternates', sans-serif;
  font-weight: 800;
  font-size: 24px;
  margin: 5px 0;
  color:darkslategray;

}

/* Section Styles */
.section {
  margin-bottom: 30px;
  margin-left:20px;
}
.section-title{
  margin-left:-20px;
}

h3 {
  font-family: 'Montserrat Alternates', sans-serif;
  font-weight: 600;
  font-size: 20px;
  margin: 20px 0 10px;
  color:darkslategray;

}

.job,
.education {
  margin-bottom: 20px;
}

h4 {
  font-family: 'Montserrat Alternates', sans-serif;
  font-weight: 400;
  font-size: 18px;
  margin: 10px 0;
  color:darkslategray;

}

/* List Styles */
ul {
  margin: 0;
  padding-left: 20px;
}

.skills-list {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}

.skills-list li {
  background-color: purple;
  color: #fff;
  border-radius: 4px;
  padding: 5px 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  transition: transform .5s ease, background-color 0.75s ease;
  cursor: pointer; /* Set the cursor to a hand */
  font-family: 'Source Code Pro', monospace;
}

.skills-list li:hover {
  transform: scale(1.1);
  background-color:rgba(128, 0, 128, 0.753); /* New color with transparency */
}

/* Link Styles */
a {
  color: purple;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.back-to-top{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: transparent;
  border: none;
  outline: none;
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 999;
  cursor: pointer;

  opacity: 0;
  transition: opacity 0.3s ease-in-out,  transform .5s ease;


}

.back-to-top.show {
  opacity: 1;
}


.back-to-top:hover {
  transform: scale(1.25);
}

.a-noStyle{
  background-color: white;
}
.a-noStyle:hover{
  background-color: white;
}

.project {
  /* ... */
  border-radius: 10px;
  border: 2px solid rgba(0, 0, 0, 0.1);
  transition: border 0.5s;
}

.project:hover {
  border: 2px solid purple;
}
