/* Apply styles to the dropdown */
select {
    padding: 8px;
    border-radius: 5px;
    border: none;
    margin-right: 10px;
    cursor: pointer;
  }
  
  /* Apply styles to the dropdown options */
  select option {
    background-color: #fff; /* Background color */
    color: #333; /* Text color */
    padding: 8px;
    transition: background-color 0.3s, color 0.3s; /* Add smooth transition */
  }
  
  /* Hover effect on options */
  select option:hover {
    background-color: #ddd; /* Change background color on hover */
    color: #000; /* Change text color on hover */
  }
  

  .option{
    background-color: white; /* Background color */
    color: #333; /* Text color */
    padding: 8px;
    transition: background-color 0.3s, color 0.3s; /* Add smooth transition */
  }
  .option:hover {
    background-color: #ddd; /* Change background color on hover */
    color: #333; /* Change text color on hover */
  }