.game-module {
    background-color: #f2f2f2;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .score {
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: bold;
    color: #000;
    margin: 0 16px;
  }
  
  