.graphics-container {
    padding-top: 100px;
    height: 100%;
    overflow-x: hidden;
    background-color: antiquewhite;
  }
  
  .grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 475px));
    justify-content: center;
    grid-gap: 25px; /* Adjust the gap between grid items */
    width: 100%;

  }
  

  .grid-item {
    position: relative;
    width: 100%;
    padding-top: 100%; /* Maintain a square aspect ratio */
    overflow: hidden; /* Hide any overflowing content */
    align-items: flex-start; /* Align items at the top */
  }
  
  .grid-item img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top; /* Align the images to the top */
    transition: transform 0.3s, z-index 0.3s;
    z-index: 1;
  }
  
  .grid-item:hover img {
    transform: scale(1.25); /* Enlarge the image on hover */
    z-index: 2; /* Bring the image to the front */
  }
  