@import url('https://cdn.jsdelivr.net/npm/three@0.132.0/examples/jsm/controls/OrbitControls.js');

.kit-preview-container {
    display: flex;
    justify-content: flex-end;
  }
  
  .kit-preview {
    position: absolute;
    width: 20%;
    height: 0;
    padding-bottom: 40%; /* Set the aspect ratio (height as a percentage of width) */
    overflow: hidden;
    margin-right: 20%;
    top: 0;
    margin-top: 5%;
  }
  .kit-preview-pants {
    position: absolute;
    width: 30%;
    height: 0;
    padding-bottom: 30%; /* Set the aspect ratio (height as a percentage of width) */
    overflow: hidden;
    margin-right: 10%;
    top: 0;
    margin-top: 5%;
  }
  .kit-preview-socks {
    position: absolute;
    width: 30%;
    height: 0;
    padding-bottom: 30%; /* Set the aspect ratio (height as a percentage of width) */
    overflow: hidden;
    margin-right: 10%;
    top: 0;
    margin-top: 5%;
  }

  .kit-preview-helmet {
    position: absolute;
    width: 34%;
    height: 0;
    padding-bottom: 17%; /* Set the aspect ratio (height as a percentage of width) */
    overflow: hidden;
    margin-right: 10%;
    top: 0;
    margin-top: 5%;
  }

  .kit-preview-branding {
    position: absolute;
    width: 30%;
    height: 75%;
    padding-bottom: 30%; /* Set the aspect ratio (height as a percentage of width) */
    overflow: hidden;
    margin-right: 10%;
    top: 0;
    margin-top: 5%;
    border-radius: 10px;
    padding: 20px;
    background-color: rgba(0,0,0,0.1);
  }

  .kit-layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .kit-custom-layer {
    position: absolute;
    top:0;
    left:0;
    width: 100%;
  }
  
  
.save-button {
  display: inline-block;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  background-color: purple;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 5px;
  padding:  5px 30px;
}

.save-button.active {
  background-color: purple;
}

.save-button:hover {
  background-color: rgb(173, 9, 173);
}

.asset-options {
  padding: 16px;
  height: 100%;
  max-width: 350px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #F5F7FA;
  /* overflow-y: scroll; */
  height: 96.5vh;
}

/* Media query for smaller screens */
@media (max-width: 600px) {
  .asset-options {
    max-width: 50%; /* Set the maximum width to 50% of the screen */
  }
}


.asset-options-container {
  position: relative;
  height: 100vh;
  overflow-y: hidden;
}

.save-button-container {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 16px;
}

.reset-button-container {
  position: absolute;
  top: 5%;
  right: 0;
  margin: 16px;
}

.tab-container{
  display: flex;
  border-bottom: 2px solid rgb(128, 0, 128);
  width: 101%;
  overflow-x: hidden;
  margin-left: -20px;
  padding-left: 30px;
  overflow-y: hidden;
  height: 50px;
  min-height: 50px;
  margin-top: -15px;
}
.tab {
  padding-top: 10px;
}
.tab:hover{
  background-color:  rgba(128, 0, 128, 0.185);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.tab button{
  border: none;
  background-color: transparent;
}
.tab-data {
  /* Styling for the tab data */
}

.off-screen {
  position: absolute;
  left: -100%; /* Move the container off-screen */
}

/* CSS */
.loading-overlay {
  /* Overlay styles */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;

  /* Loading message styles */
  color: white;
  font-size: 24px;
}

/* Apply blur effect to the body */
body.blur-effect {
  filter: blur(5px);
}

.reset-button {
  display: inline-block;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  background-color: purple;
  color: #ffffff;
  font-size: 14px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 5px;
  padding:  5px 30px;
}

.reset-button.active {
  background-color: purple;
}

.reset-button:hover {
  background-color: rgb(173, 9, 173);
}

